import { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { logEvent } from 'firebase/analytics';
import { useAnalytics, useTitle } from '../../../App';
import Heading from '../../Fragments/Heading';
import ToolLink from '../../Fragments/ToolLink';
import { useProjectRoadmap } from '../../Sections/TopMenu';
import ProjectRoadmaps from '../ProjectRoadmaps/_AllProjectRoadmaps';

function Toolkit() {
	useTitle('Toolkit');
	useProjectRoadmap(<ProjectRoadmaps.Toolkit />);
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Toolkit' });
	}, []);

	return (
		<>
			<Container fluid className='w-100 h-100 p-0'>
				<Row className='h-auto min-h-100 justify-content-center'>
					<Col md={10} lg={8} xl={6}>
						<Row className='justify-content-center'>
							<Col className='px-4 fade-in'>
								<Heading Text='Toolkit' Level={1} className='py-3 mb-5 bg-dark rounded-top-0 levitate' style={{ '--levitate-delay': '0.25s' }} />
							</Col>
						</Row>

						<Row className='justify-content-center mb-5'>
							<Col>
								<Row className='justify-content-center'>
									<Col className='mx-2 fade-in' style={{ '--fade-in-delay': '1s' }}>
										<Heading
											Text='Active Tools'
											Level={4}
											className={'bg-body mb-4 slide-in'}
											style={{ '--slide-in-delay': '0.75s', '--slide-in-x': '0%', '--slide-in-y': '-10%' }}
										/>
									</Col>
								</Row>
								<Row className='justify-content-center'>
									<Col className='mx-4 fade-in' style={{ '--fade-in-delay': '1.65s' }}>
										<ToolLink
											Disabled={true}
											ToPath='grid/designer'
											Icon='background_dot_small'
											Text='Grid Designer'
											Level={4}
											className={'slide-in'}
											style={{ '--slide-in-delay': '1.5s', '--slide-in-x': '-15%' }}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className='justify-content-center'>
							<Col>
								<Row className='justify-content-center'>
									<Col className='mx-2 fade-in' style={{ '--fade-in-delay': '1s' }}>
										<Heading
											Text='Upcoming Tools'
											Level={4}
											className={'bg-body mb-4 slide-in'}
											style={{ '--slide-in-delay': '0.75s', '--slide-in-x': '0%', '--slide-in-y': '-10%' }}
										/>
									</Col>
								</Row>

								<Row className='justify-content-center'>
									<Col className='mx-4 fade-in' style={{ '--fade-in-delay': '1.15s' }}>
										<ToolLink ToPath='dnd/stat-calc' Icon='calculate' Text='D&D Stat Calculator' Level={4} className={'slide-in'} style={{ '--slide-in-delay': '1s' }} />
									</Col>
								</Row>

								<Row className='justify-content-center'>
									<Col className='mx-4 fade-in' style={{ '--fade-in-delay': '1.4s' }}>
										<ToolLink
											Disabled={true}
											ToPath='dnd/mob-attacker'
											Icon='destruction'
											Text='D&D Mob Attacker'
											Level={4}
											className={'slide-in'}
											style={{ '--slide-in-delay': '1.25s', '--slide-in-x': '12.5%' }}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Toolkit;
