// Initialize deferredPrompt for use later to show browser install prompt.
window.deferredPrompt = null;

window.addEventListener('beforeinstallprompt', (e) => {
	// Prevent the mini-infobar from appearing on mobile
	e.preventDefault();

	// Stash the event so it can be triggered later.
	window.deferredPrompt = e;

	console.debug(`'beforeinstallprompt' event was fired and captured.`);
});

export const installPromptHandler = async () => {
	if (window.deferredPrompt) {
		// Show the install prompt
		window.deferredPrompt.prompt();
		// Wait for the user to respond to the prompt
		const { outcome } = await window.deferredPrompt.userChoice;
		// Optionally, send analytics event with outcome of user choice
		console.debug(`User response to the install prompt: ${outcome}`);
		// We've used the prompt, and can't use it again, throw it away
		window.deferredPrompt = null;
	}
};

window.addEventListener('appinstalled', () => {
	// Clear the deferredPrompt so it can be garbage collected
	window.deferredPrompt = null;

	console.debug('PWA was installed');
});

function getPWADisplayMode() {
	const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
	if (document.referrer.startsWith('android-app://')) {
		return 'twa';
	} else if (navigator.standalone || isStandalone) {
		return 'standalone';
	}
	return 'browser';
}

window.matchMedia('(display-mode: standalone)').addEventListener('change', (e) => {
	let displayMode = 'browser';
	if (e.matches) {
		displayMode = 'standalone';
	}
	// Log display mode change to analytics
	console.debug('DISPLAY_MODE_CHANGED', displayMode, getPWADisplayMode());
});
