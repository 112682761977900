import React from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from '../MaterialIcon';
import ImageCustom from '../ImageCustom';
import { getRandomNumerInRange } from '../../../API/Utilities';

SkillIcon.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	Icon: PropTypes.string,
	Label: PropTypes.string.isRequired,
	Size: PropTypes.string,
	Color: PropTypes.string,
};

function SkillIcon({ className = '', style, Icon, Image, Label, Size = '3.5rem', ColorRGB, IconRatio = 0.5, Shimmer = true, Master = false }) {
	return (
		<div className={`SkillIcon position-relative ${Master ? 'Master' : ''}`}>
			{Label && (
				<div
					className={`SkillIcon_Label bg-glass bg-glass-dark rounded-pill py-0 px-2 user-select-none`}
					style={{
						'--background-color-rgb': `${ColorRGB ?? 'var(--nmd-tk-dark-rgb)'}`,
						outline: `0.2rem solid rgba(${ColorRGB ?? 'var(--nmd-tk-dark-rgb)'})`,
					}}
				>
					{Label}
				</div>
			)}
			<div
				className={`SkillIcon_Badge position-relative d-inline-block bg-glass bg-glass-dark rounded-circle user-select-none ${Shimmer ? 'shimmer' : ''} ${className ?? ''}`}
				style={{
					'--background-color-rgb': `${ColorRGB ?? 'var(--nmd-tk-dark-rgb)'}`,
					'--shimmer-delay': `${getRandomNumerInRange(-2, 2)}`,
					width: Size,
					height: Size,
					outline: `0.2rem solid rgba(${ColorRGB ?? 'var(--nmd-tk-dark-rgb)'})`,
					...style,
				}}
			>
				{Image ? (
					<ImageCustom
						src={Image}
						alt={`Skill Icon: ${Label}`}
						className={'transform center-both'}
						style={{ width: `calc(${Size} * ${IconRatio})`, height: `calc(${Size} * ${IconRatio})`, objectFit: 'contain' }}
					/>
				) : Icon ? (
					<MaterialIcon Icon={Icon} className={'transform center-both'} style={{ fontSize: `calc(${Size} * ${IconRatio})` }} />
				) : (
					<MaterialIcon Icon={'error'} className={'transform center-both'} style={{ fontSize: `calc(${Size} * ${IconRatio})` }} />
				)}
			</div>
		</div>
	);
}

export default SkillIcon;
