import React, { useState } from 'react';
import PropTypes from 'prop-types';

ImageCustom.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
};

function ImageCustom({ className = '', style, ...otherProps }) {
	const [imageLoaded, imageLoaded_set] = useState(false);
	return (
		<img
			src=''
			alt=''
			className={`transition d-inline-block max-h-100 max-w-100 ${className}`}
			style={{ opacity: imageLoaded ? 1 : 0, ...style }}
			{...otherProps}
			onLoad={() => imageLoaded_set(true)}
		/>
	);
}

export default ImageCustom;
