import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const GlassCard = forwardRef(function GlassCard(props, ref) {
	const { className, style, ...otherProps } = props;
	return (
		<Card ref={ref} {...otherProps} className={`border-1 bg-glass bg-glass-dark shadow-pop ${className ?? ''}`} style={{ '--blur': '25px', '--background-opacity': 0.35, ...style }}>
			{props.children}
		</Card>
	);
});

export default GlassCard;
