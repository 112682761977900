import React from 'react';
import ProjectRoadmap from './ProjectRoadmap/ProjectRoadmap';

function Roadmap_GridDesigner() {
	return (
		<ProjectRoadmap
			Milestones={[
				{ Title: 'Dynamic Generated Grid', Complete: true },
				{ Title: 'Pan / Zoom Viewer', Complete: true },
				{ Title: 'Mouse Control Support', Complete: true },
				{ Title: 'Touch Control Support', Complete: true },
				{ Title: 'Settings Panel', Complete: true },
				{ Title: 'Floating Menu Button', Complete: true },
				{ Title: 'Stagger / Vertical Rows', Complete: true },
				{ Title: 'Cell Coords / Numbering', Complete: true },
				{ Title: 'Selectable Cells', Complete: true },
				{ Title: 'Palette Menu Bar', Complete: true },
				{ Title: 'Paintable Cells', Complete: true },
				{ Title: 'Keyboard Shortcuts', Complete: true },

				{ Title: 'Settings State Caching', Complete: false, InProgress: true },
				{ Title: 'Menu State Caching', Complete: false, InProgress: true },
				{ Title: 'Save Designs - Local', Complete: false, InProgress: true },
				{ Title: 'Save Palettes - Local', Complete: false, InProgress: true },
				{ Title: 'Edit History (Undo / Redo)', Complete: false },
				{ Title: 'Export Designs To Image', Complete: false },

				{ Title: 'First Usability Review', Complete: false },
				{ Title: 'Adaptations From Review', Complete: false },
				{ Title: 'Tutorial First Draft', Complete: false },

				{ Title: 'User Authentication', Complete: false },
				{ Title: 'Save Designs - Server', Complete: false },
				{ Title: 'Save Palettes - Server', Complete: false },
				{ Title: 'Share Designs / Palettes', Complete: false },

				{ Title: 'Second Usability Review', Complete: false },
				{ Title: 'Functionality Review', Complete: false },
				{ Title: 'Final Adaptations', Complete: false },
				{ Title: 'Tutorial Final Draft', Complete: false },
			]}
		/>
	);
}

export default Roadmap_GridDesigner;
