import React from 'react';
import { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../../../styles/ComponentStyles/Account.css';
import MaterialIcon from '../../Fragments/MaterialIcon';
import { useNavigate } from 'react-router-dom';
import { useAuth, useAnalytics } from '../../../App';
import UserIcon from '../../Fragments/UserIcon';

function Account({ userProfile, userProfilePrivate }) {
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Account' });
	}, []);

	const navigate = useNavigate();

	return (
		<>
			<Container fluid className='w-100 h-100'>
				<Row className='h-auto min-h-100 align-content-center justify-content-center bg-white'>
					<Col xs='auto'>
						<Row className='justify-content-center my-4'>
							<Col xs='auto'>
								<UserIcon photoUrl={userProfile.User_Photo_URL} className={'border-4'} style={{ height: '6rem' }} />
							</Col>
						</Row>

						{userProfile.User_Display_Name && (
							<Row className='justify-content-center mb-4'>
								<Col xs='auto'>
									<h3 className='text-body'>{userProfile.User_Display_Name}</h3>
								</Col>
							</Row>
						)}

						{userProfilePrivate.User_Email && (
							<Row className='justify-content-center mb-2'>
								<Col xs='auto'>
									<label className='text-body'>{userProfilePrivate.User_Email}</label>
								</Col>
							</Row>
						)}

						{userProfilePrivate.User_Phone && (
							<Row className='justify-content-center mb-2'>
								<Col xs='auto'>
									<label className='text-body'>{userProfilePrivate.User_Phone}</label>
								</Col>
							</Row>
						)}

						<Row className='justify-content-center my-5'>
							<Col>
								<div className='d-grid'>
									<Button
										variant='secondary'
										size='lg'
										className='text-white'
										onClick={() => {
											useAuth.signOut();
											navigate('/');
										}}
									>
										<span className='d-inline-block'>Sign Out</span>
										<MaterialIcon Icon={'logout'} className='ms-3' />
									</Button>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Account;
