import '../../../styles/ComponentStyles/PanelMenu.css';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from '../../Fragments/MaterialIcon';
import { Button } from 'react-bootstrap';
import PanelDivider from './PanelDivider';
import PanelButton from './PanelButton';
import PanelRadioButton from './PanelRadioButton';
import PanelCollapse from './PanelCollapse';
import PanelInput from './PanelInput';
import FloatingZone from '../FloatingContent/FloatingZone';

// FUTURE: Handle saving menu state to cache

PanelMenu.propTypes = {
	MenuOpen: PropTypes.bool,
	ClickOutClose: PropTypes.bool,
	HeaderText: PropTypes.string,
	ContentTemplate: PropTypes.element,
	FooterTemplate: PropTypes.element,
	ButtonIcon: PropTypes.string,
	ButtonStartingPosition: PropTypes.shape({
		top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
};

let initialized = false;

function PanelMenu({
	className = '',
	style,
	DefaultOpen = false,
	MenuOpen = false,
	AlwaysOpen = false,
	ClickOutClose = false,
	HeaderText = 'Menu',
	ContentTemplate,
	FooterTemplate,
	ButtonIcon = 'tune',
	ButtonStartingPosition = { top: 'calc(100% - 2.5rem)', left: 'calc(100% - 2.5rem)' },
}) {
	const [menuOpen, menuOpen_set] = useState(true);

	useEffect(() => {
		setTimeout(() => menuOpen_set(DefaultOpen), 2750);
	}, [DefaultOpen]);

	useEffect(() => {
		initialized && MenuOpen !== undefined && menuOpen_set(MenuOpen);
	}, [MenuOpen]);

	useEffect(() => {
		initialized && AlwaysOpen && menuOpen_set(AlwaysOpen);
	}, [AlwaysOpen]);

	const panelMenuRef = useRef();

	useEffect(() => {
		if (panelMenuRef.current && ClickOutClose) {
			panelMenuRef.current.addClickOutListener({
				wrapper: document.getElementsByClassName('MainContent')[0],
				clickOutCallback: () => {
					if (ClickOutClose) menuOpen_set(false);
				},
			});
		}
	}, [panelMenuRef, ClickOutClose]);

	const isMenuOpen = AlwaysOpen || menuOpen;

	return (
		<>
			<div className={`PanelMenu ${isMenuOpen ? 'open' : ''} pe-none transform position-fixed text-light max-w-100 ${className}`} style={{ zIndex: 1000, ...style }}>
				<div ref={panelMenuRef} className='PanelMenu_Content  bg-glass bg-glass-dark pe-auto d-flex flex-column transform position-relative rounded-start-4 h-100 pb-1'>
					<div className='PanelMenu_Header w-100 user-select-none'>
						<div className='h3 text-center p-3 pb-2 m-0'>{HeaderText}</div>
						<PanelMenu.Divider className={'mx-3'} />
					</div>

					<div className='PanelMenu_Body overflow-y-auto flex-grow-1 w-100 px-3 py-0'>{ContentTemplate}</div>

					{FooterTemplate && (
						<div className='PanelMenu_Footer w-100 mt-n1'>
							<PanelMenu.Divider className={'mx-3'} />
							<div className='px-3'>{FooterTemplate}</div>
						</div>
					)}
				</div>
			</div>

			{/* FUTURE: Handle push to close & reopen - might not need this with click-out to close */}
			{/* FUTURE: Handle multiple panels and positions / floating panels */}
			{!AlwaysOpen && (
				<FloatingZone
					Fullscreen={true}
					Floaters={[
						<FloatingZone.Floater
							StartingPosition={ButtonStartingPosition}
							SnapToEdges={['bottom', 'left', 'right']}
							ContentTemplate={
								<>
									<Button
										variant='primary'
										className={`PanelMenu_Button ${isMenuOpen ? 'open' : ''} rounded-circle p-0 m-0`}
										style={{ width: '3rem', height: '3rem' }}
										onClickCapture={(e) => {
											menuOpen_set(!menuOpen);
											e.stopPropagation();
										}}
									>
										<MaterialIcon Icon={ButtonIcon} className={'transform center-both fw-bold'} style={{ fontSize: '2rem' }} />
									</Button>
								</>
							}
						/>,
					]}
				/>
			)}
		</>
	);
}

PanelMenu.Button = PanelButton;
PanelMenu.Collapse = PanelCollapse;
PanelMenu.Divider = PanelDivider;
PanelMenu.Input = PanelInput;
PanelMenu.RadioButton = PanelRadioButton;

export default PanelMenu;
