import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/ComponentStyles/Headshot.css';
import Headshot_CoT from '../../images/Headshot_CoT.png';
import Headshot_GoT from '../../images/Headshot_GoT.png';
import ImageCustom from './ImageCustom';
import { getRandomNumerInRange } from '../../API/Utilities';

Headshot.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
};

const bubbleContentOptions_default = [`Hope you\\'re \\A having fun!`, `Fun Fact: \\A Dolphins are Cool`, `Have a \\A great day!`, `Fact: \\A Bears. Eat. Beets.`, `Pizza sounds \\A good...`];
// Stop peeking, you'll spoil the fun
const bubbleContentMonologue = [
	`... ... ...`,
	`How long will \\A ya keep going?`,
	`...     ... \\A     ...   `,
	`I can do this \\A all day.`,
	`...        . . .`,
	`. . .`,
	`T minus \\A . . . 10 . . .`,
	`. . . 9 . . .`,
	`. . . 8 . . .`,
	`. . . 7 . . .`,
	`. . . 6 . . .`,
	`. . . 5 . . .`,
	`. . . 4 . . .`,
	`. . . 2 . . .`,
	`Just Kidding \\A   XD`,
	`Where were we?`,
	`Oh yeah...`,
	`. . . 3 . . .`,
	`. . . 2 . . .`,
	`. . . 1 . . .`,
	`Let the \\A show begin!`,
];

// FUTURE: Add more content options
const topLines = [``, `Fact:`, `Fun Fact:`, `Tip:`, `Pro Tip:`, `Hot Take:`, `News Flash:`, `Breaking News:`, `Just In:`];
const nouns = [`Games`, `Birds`, `Cats`, `Dogs`, `Carrots`, `Websites`, `Noahs`, `Apples`];
const adjectives = [`Cool`, `Silly`, `???`, `Good`, `Bad`, `Smart`, `Food`, `Neat`];

const generateRandomSaying = () => {
	let topLine = topLines[Math.round(getRandomNumerInRange(0, topLines.length - 1))];
	let noun = nouns[Math.round(getRandomNumerInRange(0, nouns.length - 1))];
	let adjective = adjectives[Math.round(getRandomNumerInRange(0, adjectives.length - 1))];
	let randomSaying = `${topLine}${topLine.length > 0 ? '\\A' : ''} ${noun} are ${adjective}`;

	return randomSaying;
};

function Headshot({ className = '', style, Size = '250px' }) {
	const headshotRef = useRef();
	const [hovering, hovering_set] = useState(false);
	const [maskPosition, maskPosition_set] = useState('90% 75%');

	const [hoverCount, hoverCount_set] = useState(0);
	const [bubbleContentOptions, bubbleContentOptions_set] = useState([...bubbleContentOptions_default]);
	const [bubbleContent, bubbleContent_set] = useState('Thanks for\\Astopping by!');

	const updateBubbleContent = useCallback(() => {
		let newContent = '';

		if (hoverCount === 1 + bubbleContentOptions_default.length + bubbleContentMonologue.length) {
			// TODO: Make the show
			console.log('Let the show begin!');
		} else if (hoverCount > bubbleContentOptions_default.length) {
			newContent = bubbleContentMonologue[hoverCount - (bubbleContentOptions_default.length + 1)] ?? generateRandomSaying();
		} else {
			let optionNumber = Math.round(getRandomNumerInRange(0, bubbleContentOptions.length - 1)),
				newContentOptions = [];

			newContent = bubbleContentOptions[optionNumber];
			newContentOptions = [...bubbleContentOptions];
			newContentOptions.splice(optionNumber, 1);
			newContentOptions = [...(newContentOptions.length > 0 ? newContentOptions : bubbleContentOptions_default)];

			bubbleContentOptions_set([...newContentOptions]);
		}

		bubbleContent_set(newContent);
	}, [hoverCount, bubbleContentOptions]);

	const handleMouseEnter = useCallback(
		(e) => {
			if (headshotRef.current) {
				let emblemBounds = headshotRef.current.getBoundingClientRect();
				let topLeftDelta = { x: e.pageX - emblemBounds.left, y: e.pageY - emblemBounds.top };
				let newPosition = `${(topLeftDelta.x / emblemBounds.width) * 100}% ${(topLeftDelta.y / emblemBounds.height) * 100}%`;

				maskPosition_set(newPosition);
				hoverCount_set(hoverCount + 1);
				setTimeout(() => {
					hovering_set(true);
				}, 100);
			}
		},
		[hoverCount]
	);

	const handleMouseLeave = useCallback(
		(e) => {
			if (headshotRef.current) {
				let emblemBounds = headshotRef.current.getBoundingClientRect();
				let topLeftDelta = { x: e.pageX - emblemBounds.left, y: e.pageY - emblemBounds.top };
				let newPosition = `${(topLeftDelta.x / emblemBounds.width) * 100}% ${(topLeftDelta.y / emblemBounds.height) * 100}%`;

				maskPosition_set(newPosition);
				setTimeout(() => {
					hovering_set(false);
					updateBubbleContent();
				}, 100);
			}
		},
		[updateBubbleContent]
	);

	const handleClick = useCallback(
		(e) => {
			if (hovering) {
				handleMouseLeave(e);
				setTimeout(() => {
					handleMouseEnter(e);
				}, 375);
			}
		},
		[hovering, handleMouseLeave, handleMouseEnter]
	);

	return (
		<div
			ref={headshotRef}
			className={`Headshot ${
				hovering ? 'hover' : ''
			} d-inline-block cursor-pointer user-select-none transition transform position-relative rounded-circle bg-glass bg-glass-light border border-5 border-light shadow-pop ${className ?? ''}`}
			style={{ '--bubble-content': `'${bubbleContent}'`, width: Size, height: Size, ...style }}
			onClick={handleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className='w-100 h-100 rounded-circle overflow-hidden position-relative'>
				<ImageCustom src={Headshot_CoT} alt='Noah Miller Portrait' className='Headshot_CoT pe-none transition transform position-relative w-100 h-100' style={{ objectFit: 'cover' }} />

				<ImageCustom
					src={Headshot_GoT}
					alt='Noah Miller Portrait'
					className='Headshot_GoT pe-none transition transform center-horizontal w-100 h-100'
					style={{ objectFit: 'cover', '--mask-start': maskPosition }}
				/>
			</div>
		</div>
	);
}

export default Headshot;
