import React from 'react';
import PropTypes from 'prop-types';

MaterialIcon.propTypes = {
	IconType: PropTypes.oneOf(['outlined', 'rounded', 'sharp', null]),
};

function MaterialIcon({ IconType, Icon, className, style }) {
	return (
		<>
			<span
				className={`${className ?? ''} material-icon material-symbols-${
					IconType ?? 'rounded'
				}`}
				style={style}
			>
				{Icon ?? 'disabled_by_default'}
			</span>
		</>
	);
}

export default MaterialIcon;
