import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from '../../../API/Utilities';

FloatingContent.propTypes = {
	TouchOnly: PropTypes.bool,
	ContentTemplate: PropTypes.element,
	HandleContactStart: PropTypes.func,
	CurrentPosition: PropTypes.shape({
		top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	StartingPosition: PropTypes.shape({
		top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	CurrentHolding: PropTypes.bool,
	SnapToEdge: PropTypes.arrayOf(PropTypes.oneOf(['top', 'bottom', 'left', 'right'])),
	SingleSnap: PropTypes.bool,
	Snapping: PropTypes.bool,
	HoldDelay: PropTypes.number,
};

function FloatingContent({
	Key,
	TouchOnly = true,
	ContentTemplate,
	HandleContactStart,
	CurrentPosition,
	CurrentHolding,
	StartingPosition = { top: '50%', left: '50%' },
	SnapToEdges = ['top', 'bottom', 'left', 'right'],
	SingleSnap = true,
	Snapping = false,
	HoldDelay = 250,
}) {
	const contentRef = useRef();
	const [holding, holding_set] = useState(false);
	const [position, position_set] = useState(StartingPosition);

	useEffect(() => {
		if (holding && CurrentPosition) position_set(CurrentPosition);
	}, [CurrentPosition, holding]);

	useEffect(() => {
		holding_set(CurrentHolding);
	}, [CurrentHolding]);

	return (
		<div
			ref={(el) => (contentRef.current = el)}
			id={Key}
			className={`FloatingContent ${holding ? 'holding' : ''} ${Snapping ? 'transition' : ''} bg-light text-dark rounded-pill user-select-none position-absolute transform`}
			style={{
				pointerEvents: 'all',
				zIndex: isNumber(position.top) ? position.top : 1,
				'--transition-duration': '0.5s',
				'--transition-timing-function': 'var(--timing-func-spring-out-soft)',
				'--translateY': '-50%',
				'--translateX': '-50%',
				'--top': isNumber(position.top) ? `${position.top}px` : position.top,
				'--left': isNumber(position.left) ? `${position.left}px` : position.left,
			}}
			onMouseDown={(e) => {
				!TouchOnly && HandleContactStart && typeof HandleContactStart === 'function' && HandleContactStart(HoldDelay, contentRef.current);
			}}
			onTouchStart={(e) => {
				if (e.targetTouches.length === 1) {
					HandleContactStart && typeof HandleContactStart === 'function' && HandleContactStart(HoldDelay, contentRef.current, SnapToEdges, SingleSnap);
				}
			}}
		>
			{ContentTemplate}
		</div>
	);
}

export default FloatingContent;
