export const dayNameByDayIndex = (dayIndex) => {
	const index = toNumber(dayIndex);
	switch (index) {
		case 0:
			return 'Sunday';
		case 1:
			return 'Monday';
		case 2:
			return 'Tuesday';
		case 3:
			return 'Wednesday';
		case 4:
			return 'Thursday';
		case 5:
			return 'Friday';
		case 6:
			return 'Saturday';
		default:
			return null;
	}
};

export const isNumber = (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0);
export const toNumber = (n) => (isNumber(n) ? parseFloat(n) : undefined);

export const getRandomNumerInRange = (end1 = 0, end2 = 1) => {
	const min = Math.min(end1, end2);
	const max = Math.max(end1, end2);
	return convertNumberToRange(Math.random(), 0, 1, min, max);
	// return Math.floor(Math.random() * (max - min + 1) + min);
};

export const convertNumberToRange = (value, OldMin, OldMax, NewMin, NewMax) => {
	return toNumber(((value - OldMin) / (OldMax - OldMin)) * (NewMax - NewMin) + NewMin);
};

export const getPrecision = (n) => (`${n}`.indexOf('.') > -1 ? `${n}`.split('.')[1].length : 0);

export const toStep = (n, step = 0.5) => {
	let inverseStep = step < 1 ? 1.0 / step : step;
	return (Math.round((toNumber(n) ?? 0) * inverseStep) / inverseStep).toFixed(getPrecision(step));
};

export const inToMM = (n, precision = 10) => Math.round((toNumber(n) ?? 0) * precision * 25.4) / precision;
export const mmToIN = (n, precision = 10) => Math.round(((toNumber(n) ?? 0) * precision) / 25.4) / precision;

export const toggleBodyClass = (className, state) => toggleElementClass(document.body, className, state);
export const toggleElementClass = (element, className, state) => element.classList.toggle(className ?? '', state);

export const isElementFullyVisible = (el, holder) => {
	holder ??= document.body;
	const {
		top: el_top,
		bottom: el_bottom,
		left: el_left,
		right: el_right,
		// width: el_width,
		// height: el_height,
	} = el.getBoundingClientRect();
	const {
		top: holder_top,
		bottom: holder_bottom,
		left: holder_left,
		right: holder_right,
		// width: holder_width,
		// height: holder_height,
	} = holder.getBoundingClientRect();

	return el_top >= holder_top && el_bottom <= holder_bottom && el_left >= holder_left && el_right <= holder_right;
};

export const scrollElementIntoView = ({ el, center = true, instant = false }) => {
	// const parent = el.parentElement;
	const parent = getScrollParent(el);
	const offset_top = center ? el.offsetTop + el.getBoundingClientRect().height / 2 - parent.getBoundingClientRect().height / 2 : el.offsetTop;
	const offset_left = center ? el.offsetLeft + el.getBoundingClientRect().width / 2 - parent.getBoundingClientRect().width / 2 : el.offsetLeft;
	parent.scrollTo({ top: offset_top, left: offset_left, behavior: instant ? 'instant' : 'smooth' });
};

const REGEXP_SCROLL_PARENT = /^(visible|hidden)/;

export const getScrollParent = (el) =>
	!(el instanceof HTMLElement) || typeof window.getComputedStyle !== 'function'
		? null
		: el.scrollHeight >= el.clientHeight && !REGEXP_SCROLL_PARENT.test(window.getComputedStyle(el).overflowY || 'visible')
		? el
		: getScrollParent(el.parentElement) || document.body;

// position.coords.latitude
// position.coords.longitude
export const getCurrentLocation = (successCallback, errorCallback) => {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				typeof successCallback === 'function' && successCallback(position);
			},
			(error) => {
				typeof errorCallback === 'function' && errorCallback(error);
			},
			{
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0,
			}
		);
	} else {
		typeof errorCallback === 'function' && errorCallback('Geolocation is not supported by this browser');
	}
};

window.watchCurrentLocationID = null;
var latestLocation;
var lastLocationUpdateTimestamp = null;
const locationUpdateCooldown = 5000;
window.locationUpdateTimeout = null;

export const watchCurrentLocation = (successCallback, errorCallback) => {
	console.log('starting watch location');
	if (navigator.geolocation) {
		if (window.watchCurrentLocationID == null) {
			window.watchCurrentLocationID = navigator.geolocation.watchPosition(
				(position) => {
					latestLocation = position;
					console.log('New Location Available', latestLocation, window.watchCurrentLocationID);

					let currentTimestamp = new Date();
					const timestampDelta = currentTimestamp - lastLocationUpdateTimestamp;
					console.log(timestampDelta);

					if (lastLocationUpdateTimestamp === null || timestampDelta > locationUpdateCooldown) {
						console.log('location update is not cooling down');
						typeof successCallback === 'function' && successCallback(latestLocation);
						lastLocationUpdateTimestamp = currentTimestamp;
					} else {
						console.log('location update is cooling down');

						if (window.locationUpdateTimeout === null) {
							window.locationUpdateTimeout = setTimeout(() => {
								console.log('location update is cooled down', new Date() - lastLocationUpdateTimestamp);

								typeof successCallback === 'function' && successCallback(latestLocation);
								lastLocationUpdateTimestamp = new Date();

								clearTimeout(window.locationUpdateTimeout);
								window.locationUpdateTimeout = null;
							}, locationUpdateCooldown - timestampDelta);
						}
					}
				},
				(error) => {
					typeof errorCallback === 'function' && errorCallback(error);
				},
				{
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 5000,
				}
			);
		} else {
			console.log('Geolocation is already being watched', window.watchCurrentLocationID);
		}
	} else {
		typeof errorCallback === 'function' && errorCallback('Geolocation is not supported by this browser');
	}
};

export const clearLocationWatch = () => {
	if (navigator.geolocation && window.watchCurrentLocationID != null) {
		console.log('stopping watch location', window.watchCurrentLocationID);
		navigator.geolocation.clearWatch(window.watchCurrentLocationID);
		window.watchCurrentLocationID = null;
	}

	if (window.locationUpdateTimeout != null) {
		clearTimeout(window.locationUpdateTimeout);
		window.locationUpdateTimeout = null;
	}
};

export const compare = (obj_1, obj_2) => {
	return obj_1 > obj_2 ? 1 : obj_1 < obj_2 ? -1 : 0;
};

// This isn't needed for "Sat Jun 08 2024 15:00:59 GMT+0000 (Coordinated Universal Time)" format,
// which is how HistoricalConnection_DisplayTimestamp is currently stored, so don't use it for that.
// Instead just use "new Date(HistoricalConnection_DisplayTimestamp).toLocaleString()"
export const convertUTCDateToLocalDate = (utcDate) => {
	utcDate = new Date(utcDate);
	let localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);
	return localDate;
};

export const generateRandomElementId = () => {
	return `_el_${getRandomUUID()}`;
};

export const getRandomUUID = () => {
	return (
		(crypto && crypto.randomUUID && crypto.randomUUID()) ??
		'10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) => (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16))
	);
};

export const rgbToHex = ({ r, g, b }) => '#' + [r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('');
export const hexToRgb = (hex) =>
	hex
		.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
		.substring(1)
		.match(/.{2}/g)
		.map((x) => parseInt(x, 16));

export const getRandomHexValue = () => rgbToHex(getRandomRgbData());

export const getRandomRgbValue = () => {
	let rgbData = getRandomRgbData();
	return `${rgbData.r}, ${rgbData.g}, ${rgbData.b}`;
};

export const getRandomRgbData = () => {
	return { r: Math.round(getRandomNumerInRange(0, 255)), g: Math.round(getRandomNumerInRange(0, 255)), b: Math.round(getRandomNumerInRange(0, 255)) };
};

export const getInverseRgbData = ({ r, g, b }) => {
	return { r: 255 - r, g: 255 - g, b: 255 - b };
};
