import React from '../../images/BrandIcons/React.png';
import NodeJs from '../../images/BrandIcons/NodeJs.png';
import DotNET from '../../images/BrandIcons/DotNET.png';
import Blazor from '../../images/BrandIcons/Blazor.png';
import HTML5 from '../../images/BrandIcons/HTML5.png';
import CSS3 from '../../images/BrandIcons/CSS3.png';
import HtmlCssJs from '../../images/BrandIcons/HtmlCssJs.png';
import Firebase from '../../images/BrandIcons/Firebase.png';

import Photoshop from '../../images/BrandIcons/Photoshop.png';
import Premiere from '../../images/BrandIcons/Premiere.png';
import Illustrator from '../../images/BrandIcons/Illustrator.png';
import AfterEffects from '../../images/BrandIcons/AfterEffects.png';
import Audition from '../../images/BrandIcons/Audition.png';
import XD from '../../images/BrandIcons/XD.png';

import Fusion360 from '../../images/BrandIcons/Fusion360Icon.png';
import Meshmixer from '../../images/BrandIcons/Meshmixer.png';
import Unity from '../../images/BrandIcons/Unity.png';
import DevOps from '../../images/BrandIcons/DevOps.png';

function Icons() {}

Icons.React = React;
Icons.NodeJs = NodeJs;
Icons.DotNET = DotNET;
Icons.Blazor = Blazor;
Icons.HTML5 = HTML5;
Icons.CSS3 = CSS3;
Icons.HtmlCssJs = HtmlCssJs;
Icons.Firebase = Firebase;

Icons.Photoshop = Photoshop;
Icons.Premiere = Premiere;
Icons.Illustrator = Illustrator;
Icons.AfterEffects = AfterEffects;
Icons.Audition = Audition;
Icons.XD = XD;

Icons.Fusion360 = Fusion360;
Icons.Meshmixer = Meshmixer;
Icons.Unity = Unity;
Icons.DevOps = DevOps;

export default Icons;
