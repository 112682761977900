import React, { cloneElement, useRef } from 'react';
import PropTypes from 'prop-types';
import { Bounce, gsap, useGSAP } from '../../API/gsap';

TextRoller.propTypes = {
	Rollers: PropTypes.arrayOf(PropTypes.element).isRequired,
};

function TextRoller({ className = '', style, Rollers = [], Repeat = -1, RepeatDelay = 2, RollBack = false, StartDelay = 0, StepDuration = 0.75, StepHold = 0.5 }) {
	const textRollerRef = useRef();

	useGSAP(
		() => {
			const textRollers = gsap.utils.toArray(`.TextRoller_String`);

			const textRollerTimeline = gsap.timeline({
				repeat: Repeat,
				repeatDelay: 0,
				delay: StartDelay,
				defaults: { duration: StepDuration, ease: Bounce.easeOut },
				onComplete: () => {
					if (Repeat > 0) {
						gsap.timeline({
							delay: StepHold,
							defaults: { duration: StepDuration, ease: Bounce.easeOut },
						})
							.fromTo(
								textRollers[0],
								{
									'--rotateX': 90,
									'--translateY': '-100%',
									'--translateZ': '0rem',
								},
								{
									'--rotateX': 0,
									'--translateY': '-50%',
									'--translateZ': '2.5rem',
								}
							)
							.fromTo(
								textRollers[0],
								{
									opacity: 0,
									duration: 0.05,
									ease: 'none',
								},
								{
									opacity: 1,
									duration: 0.05,
									ease: 'none',
								},
								`<-=0.05`
							);
					}
				},
			});

			textRollers.map((roller, index) => {
				textRollerTimeline
					.fromTo(
						roller,
						{
							'--rotateX': 90,
							'--translateY': '-100%',
							'--translateZ': '0rem',
						},
						{
							'--rotateX': 0,
							'--translateY': '-50%',
							'--translateZ': '2.5rem',
						},
						`<-=${StepDuration - 0.05}`
					)
					.fromTo(
						roller,
						{
							opacity: 0,
							duration: 0.05,
							ease: 'none',
						},
						{
							opacity: 1,
							duration: 0.05,
							ease: 'none',
						},
						`<-=0.05`
					);

				if (index !== textRollers.length - 1) {
					textRollerTimeline
						.to(
							roller,
							{
								'--rotateX': -90,
								'--translateY': '0',
								'--translateZ': '0rem',
							},
							`>+=${StepDuration + StepHold}`
						)
						.to(
							roller,
							{
								opacity: 0,
								duration: 0.05,
								ease: 'none',
							},
							`>-=0.05`
						);
				} else {
					textRollerTimeline.to(roller, {}, `>+=${StepDuration + StepHold}`);
				}

				return index;
			});

			if (Repeat > 0 || Repeat === -1) {
				textRollerTimeline.to({}, { duration: RepeatDelay });

				if (RollBack) {
					textRollers.reverse().map((roller, index) => {
						if (index !== 0) {
							textRollerTimeline
								.to(
									roller,
									{
										'--rotateX': 0,
										'--translateY': '-50%',
										'--translateZ': '2.5rem',
										duration: StepDuration / 4,
										ease: 'none',
									},
									`<-=${StepDuration / 4 - 0.05}`
								)
								.to(
									roller,
									{
										opacity: 1,
										duration: 0.05,
										ease: 'none',
									},
									`<-=0.05`
								);
						}

						textRollerTimeline
							.to(roller, {
								'--rotateX': 90,
								'--translateY': '-100%',
								'--translateZ': '0rem',
								duration: StepDuration / 4,
								ease: 'none',
							})
							.to(
								roller,
								{
									opacity: 0,
									duration: 0.05,
									ease: 'none',
								},
								`>-=0.05`
							);

						return index;
					});
				} else {
					textRollerTimeline
						.to(textRollers[textRollers.length - 1], {
							'--rotateX': -90,
							'--translateY': '0',
							'--translateZ': '0rem',
						})
						.to(
							textRollers[textRollers.length - 1],
							{
								opacity: 0,
								duration: 0.05,
								ease: 'none',
							},
							`>-=0.05`
						);
				}
			}
		},
		{ scope: textRollerRef }
	);

	return (
		<span ref={textRollerRef} className={`transform-3d position-relative d-inline-block lh-1 ${className ?? ''}`} style={{ ...style }}>
			<span className='text-nowrap overflow-hidden d-inline-block h-0 text-start' style={{ lineHeight: 0 }}>
				{Rollers &&
					Rollers.length > 0 &&
					Rollers.map((roller, idx) => {
						return (
							<span key={idx}>
								<span className={`text-nowrap text-capitalize d-inline-block ${className ?? ''}`} style={{ lineHeight: 0, ...style }}>
									{roller.props.Text ?? ''}
								</span>
								<br />
							</span>
						);
					})}
			</span>
			{Rollers &&
				Rollers.length > 0 &&
				Rollers.map((roller, idx) => {
					return cloneElement(roller, { key: idx });
				})}
		</span>
	);
}

function Roller({ className = '', style, Text }) {
	return (
		<span className={`TextRoller_String transform transform-3d text-nowrap text-capitalize lh-1 ${className ?? ''}`} style={{ '--left': '0px', '--top': '50%', ...style }}>
			<span>{Text ?? ''}</span>
		</span>
	);
}

TextRoller.Roller = Roller;

export default TextRoller;
