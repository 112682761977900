import { Button, Col, Row } from 'react-bootstrap';
import MaterialIcon from '../../Fragments/MaterialIcon';
import PropTypes from 'prop-types';

PanelButton.propTypes = {
	IconPosition: PropTypes.oneOf(['start', 'end']),
};

function PanelButton({ className = '', style, variant, size, Icon, IconPosition = 'start', Label, HandleButtonClick }) {
	const buttonClicked = () => {
		HandleButtonClick && typeof HandleButtonClick === 'function' && HandleButtonClick();
	};

	return (
		<Row className='my-3'>
			<Col>
				<Button variant={variant} size={size} className={`w-100 rounded-pill text-uppercase py-2 ${className}`} style={{ ...style }} onClick={buttonClicked}>
					<Row className='justify-content-center'>
						{Icon && (
							<Col xs={'auto'} className={`px-1 ${IconPosition === 'end' ? 'order-last' : 'order-first'}`}>
								<MaterialIcon Icon={Icon} style={{ verticalAlign: 'text-top' }} />
							</Col>
						)}
						<Col xs={'auto'} className='px-1'>
							{Label}
						</Col>
					</Row>
				</Button>
			</Col>
		</Row>
	);
}

export default PanelButton;
