import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
// import { hexToRgb } from '../../API/Utilities';

PaletteSwatch.propTypes = {
	DefaultValue: PropTypes.string,
	CurrentValue: PropTypes.string,
	HandleValueChange: PropTypes.func,
	HandleSwatchSelect: PropTypes.func,
};

function PaletteSwatch({ DefaultValue = '#000000', CurrentValue, HandleValueChange, HandleSwatchSelect }) {
	const swatchRef = useRef();

	const [inputValue, inputValue_set] = useState(CurrentValue ?? DefaultValue);

	const valueChanged = (newValue) => {
		inputValue_set(newValue);
		HandleValueChange && typeof HandleValueChange === 'function' && HandleValueChange(newValue);
	};

	useEffect(() => {
		inputValue_set(CurrentValue ?? DefaultValue);
	}, [CurrentValue, DefaultValue]);

	// useEffect(() => {
	// 	if (swatchRef.current) {
	// 		swatchRef.current.addLongPressListener((e) => {
	// 			HandleSwatchSelect && typeof HandleSwatchSelect === 'function' && HandleSwatchSelect(e);
	// 		});
	// 	}
	// }, [swatchRef, HandleSwatchSelect]);

	return (
		<Form.Control
			ref={swatchRef}
			title={`Click here or type it's number to make this swatch active. Click again to edit this swatch's color.`}
			className='PaletteSwatch bg-dark rounded-circle border border-2 p-0 focus-ring focus-ring-none overflow-hidden'
			style={{ width: 'var(--swatch-size)', height: 'var(--swatch-size)' }}
			type='color'
			value={inputValue}
			onChange={(e) => valueChanged(e.target.value)}
			onClickCapture={(e) => HandleSwatchSelect && typeof HandleSwatchSelect === 'function' && HandleSwatchSelect(e)}
		/>
	);
}

export default PaletteSwatch;
