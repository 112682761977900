import { useEffect, useRef, useState } from 'react';
import '../../../styles/RouteStyles/NoahMillerDesign.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { logEvent } from 'firebase/analytics';
import { useAnalytics, useTitle } from '../../../App';
import { useProjectRoadmap } from '../../Sections/TopMenu';
import ProjectRoadmaps from '../ProjectRoadmaps/_AllProjectRoadmaps';
import ActiveBackground from '../../Sections/ActiveBackground';
import { Breakpoints, useBreakpoint, useContentBounds } from '../Layouts/Main';
import { gsap, Power1, useGSAP } from '../../../API/gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Emblem from '../../Fragments/Emblem';
import TextRoller from '../../Fragments/TextRoller';
import GlassCard from '../../Fragments/GlassCard';
import Headshot from '../../Fragments/Headshot';
import SkillsGroup from '../../Fragments/Skills/SkillsGroup';
import Icons from '../../Fragments/Icons';

function NoahMillerDesign() {
	useTitle('Independent Creator');
	useProjectRoadmap(<ProjectRoadmaps.NoahMillerDesign />);
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Toolkit' });
	}, []);

	const [loaded, loaded_set] = useState(false);
	const [isPortrait, isPortrait_set] = useState();

	const { contentBounds } = useContentBounds();
	const breakpoint = useBreakpoint();

	useEffect(() => {
		isPortrait_set(contentBounds && contentBounds.width < contentBounds.height);
		if (contentBounds && !loaded) {
			loaded_set(true);
		}
	}, [contentBounds, loaded]);

	const scrollingContentRef = useRef();
	const bioContainerRef = useRef();
	const bioHeadshotRef = useRef();
	const bioCardRef = useRef();
	const bioStatsRef = useRef();
	const bioQuoteRef = useRef();
	const emblemRef = useRef();

	const [cleanBioQuote, cleanBioQuote_set] = useState(false);

	useGSAP(() => {
		if (!breakpoint || breakpoint <= Breakpoints.sm) return;

		gsap.timeline({
			scrollTrigger: {
				scroller: scrollingContentRef.current,
				trigger: bioContainerRef.current,
				scrub: 2.75,
				start: 'top bottom',
				end: 'center 60%',
				markers: false,
			},
		})
			.fromTo(
				bioHeadshotRef.current,
				{
					yPercent: -100,
					autoAlpha: 0,
				},
				{
					yPercent: 0,
					autoAlpha: 1,
					ease: Power1.out,
				}
			)
			.fromTo(
				bioCardRef.current,
				{
					yPercent: -100,
					autoAlpha: 0,
				},
				{
					yPercent: 0,
					autoAlpha: 1,
					ease: Power1.out,
				},
				'<'
			);

		gsap.timeline({
			scrollTrigger: {
				scroller: scrollingContentRef.current,
				trigger: bioContainerRef.current,
				scrub: 2.75,
				start: 'top 60%',
				end: 'center center',
				markers: false,
			},
		})
			.fromTo(
				bioStatsRef.current,
				{
					x: -100,
					autoAlpha: 0,
				},
				{
					x: 0,
					autoAlpha: 1,
					ease: Power1.out,
				}
			)
			.fromTo(
				bioQuoteRef.current,
				{
					x: 100,
					autoAlpha: 0,
				},
				{
					x: 0,
					autoAlpha: 1,
					ease: Power1.out,
				},
				'<'
			);

		ScrollTrigger.create({
			scroller: '.MainContent',
			trigger: bioContainerRef.current,
			start: 'top bottom',
			end: 'bottom top',
			onToggle: (self) => console.log('toggled, isActive:', self.isActive),
			onUpdate: (self) => {
				// console.log('progress:', self.progress.toFixed(3), 'direction:', self.direction, 'velocity', self.getVelocity());
			},
		});
	}, [breakpoint]);

	// const { contextSafe } = useGSAP({ scope: testContainer });
	// const onClickGood = contextSafe(() => {
	// 	gsap.to(testingScroll.current, { rotation: '+=360' });
	// });

	return (
		<>
			<Container ref={scrollingContentRef} fluid className='w-100 h-100 p-0 scroll-snap-container-off overflow-y-scroll overflow-x-hidden' style={{}}>
				<ActiveBackground scroller={scrollingContentRef} trigger={emblemRef} />
				<div ref={emblemRef} className='scroll-snap-element position-relative w-100 h-100'>
					{loaded && (
						<Emblem
							Emblem={isPortrait ? 'Pawn' : 'NM'}
							className='fade-in'
							style={{
								'--fade-in-delay': '5.65s',
								'--fade-in-duration': '2.25s',
								mixBlendMode: 'exclusion',
							}}
						/>
					)}

					<div
						className='fade-in pe-none user-select-none transform center-both py-4 bg-glass bg-glass-colored text-center w-100'
						style={{
							'--fade-in-delay': '0.25s',
							'--fade-in-duration': '1.25s',
							'--blur': '15px',
							'--background-opacity': 0.2,
							'--background-color-rgb': 'var(--nmd-tk-body-rgb)',
						}}
					>
						<div
							style={{
								fontSize: 'clamp(1rem, calc(100vw / 10), 12.5rem)',
								'--title-font-weight': 700,
								'--title-outline-width': 'calc(clamp(1rem, calc(100vw / 20), 10rem) / 25)',
								'--title-outline-color': 'rgba(var(--nmd-tk-body-bg-rgb), 0.15)',
							}}
						>
							<span
								className='text-outline font-brand'
								style={{
									fontWeight: 'var(--title-font-weight)',
									'--outline-text-color': 'var(--nmd-tk-white)',
									'--outline-width': 'var(--title-outline-width)',
									'--outline-color': 'var(--title-outline-color)',
								}}
							>
								NoahMiller
							</span>
							<TextRoller
								style={{
									margin: '0 calc(clamp(1rem, calc(100vw / 20), 10rem) / 10)',
								}}
								RepeatDelay={7.5}
								StepDuration={0.5}
								StartDelay={2.5}
								Rollers={[
									<TextRoller.Roller
										Text={'Ideate'}
										className='text-outline font-brand'
										style={{
											'--outline-text-color': 'var(--nmd-tk-cyan)',
											'--outline-width': 'var(--title-outline-width)',
											'--outline-color': 'var(--title-outline-color)',
											fontWeight: 'var(--title-font-weight)',
										}}
									/>,
									<TextRoller.Roller
										Text={'Explore'}
										className='text-outline font-brand'
										style={{
											'--outline-text-color': 'var(--nmd-tk-teal)',
											'--outline-width': 'var(--title-outline-width)',
											'--outline-color': 'var(--title-outline-color)',
											fontWeight: 'var(--title-font-weight)',
										}}
									/>,
									<TextRoller.Roller
										Text={'Create'}
										className='text-outline font-brand'
										style={{
											'--outline-text-color': 'var(--nmd-tk-pink)',
											'--outline-width': 'var(--title-outline-width)',
											'--outline-color': 'var(--title-outline-color)',
											fontWeight: 'var(--title-font-weight)',
										}}
									/>,
									<TextRoller.Roller Text={''} />,
									<TextRoller.Roller
										Text={'Design'}
										className='text-outline font-brand'
										style={{
											'--outline-text-color': 'var(--nmd-tk-white)',
											'--outline-width': 'var(--title-outline-width)',
											'--outline-color': 'var(--title-outline-color)',
											fontWeight: 'var(--title-font-weight)',
										}}
									/>,
								]}
							/>
						</div>
					</div>
				</div>

				<Row className='d-xs-none scroll-snap-element h-25 justify-content-center align-content-center'>
					<Col xs={'auto'}></Col>
				</Row>

				<Row ref={bioContainerRef} className='scroll-snap-element min-h-100 justify-content-center align-content-center'>
					<Col md={12} lg={11} xl={11} xxl={9} className='p-4' style={{ maxWidth: '1200px', '--bio-size': '350px' }}>
						<Row className='justify-content-center g-5 mb-5'>
							<Col xs={12} lg={'auto'}>
								<Row className='justify-content-center'>
									<Col ref={bioHeadshotRef} xs={'auto'}>
										<Headshot Size='350px' />
									</Col>
								</Row>
							</Col>

							<Col xs={12} xl>
								<GlassCard
									ref={bioCardRef}
									className={`w-100 py-4 px-2 px-sm-5`}
									style={{ minHeight: 'var(--bio-size)', borderRadius: breakpoint > Breakpoints.sm ? 'calc(var(--bio-size) / 2)' : 'var(--nmd-tk-border-radius-xxl)' }}
								>
									<Card.Body className='d-flex flex-column justify-content-center'>
										<Row>
											<Col>
												<h1 className='mb-0 w-100 text-center text-capitalize font-brand lh-1' style={{ fontSize: '4rem' }}>
													Noah Miller
												</h1>
											</Col>
										</Row>
										<Row>
											<Col>
												<hr className='hr mx-n3 rounded-pill' />
											</Col>
										</Row>
										<Row className='mb-2 px-0 px-sm-2 px-md-4'>
											<Col>
												<p
													className='mb-0'
													style={{
														fontSize: '1.5rem',
													}}
												>
													I'm an independent designer who loves to create unique, engaging experiences. I work with a vast range of tech and mediums to craft memorable
													content that leaves you intrigued and wanting more.
												</p>
											</Col>
										</Row>
									</Card.Body>
								</GlassCard>
							</Col>
						</Row>

						<Row className='justify-content-center g-5'>
							<Col xs={'auto'}>
								<GlassCard
									ref={bioStatsRef}
									className={`py-4 px-2 px-sm-5`}
									style={{
										width: breakpoint > Breakpoints.md ? 'var(--bio-size)' : 'auto',
										borderRadius: breakpoint > Breakpoints.sm ? 'calc(var(--bio-size) / 2)' : 'var(--nmd-tk-border-radius-xxl)',
									}}
								>
									<Card.Body className='d-flex flex-column justify-content-center'>
										<Row className='mt-2'>
											<Col>
												<h1 className='mb-4 w-100 text-center text-capitalize font-brand lh-1' style={{ fontSize: '3rem' }}>
													Core Skills
												</h1>
											</Col>
										</Row>

										{/* <Row>
											<Col>
												<hr className='hr mx-n3 mb-4 rounded-pill' />
											</Col>
										</Row> */}

										<Row>
											<Col>
												<SkillsGroup
													GroupName='Code'
													Skills={[
														// <SkillsGroup.Icon Image={Icons.DotNET} Label={'.NET'} ColorRGB={'23, 23, 23'} IconRatio={0.55} />,
														<SkillsGroup.Icon Image={Icons.React} Label={'React'} ColorRGB={'35, 39, 47'} IconRatio={0.65} Master={true} />,
														<SkillsGroup.Icon Image={Icons.Blazor} Label={'Blazor WASM / Server'} ColorRGB={'var(--nmd-tk-light-rgb)'} IconRatio={0.65} />,
														<SkillsGroup.Icon Image={Icons.HtmlCssJs} Label={'HTML 5 | CSS 3 | JavaScript'} IconRatio={0.7} Master={true} />,
														<SkillsGroup.Icon Image={Icons.Firebase} Label={'Firebase'} ColorRGB={'18, 18, 18'} IconRatio={0.7} />,
														<SkillsGroup.Icon Image={Icons.NodeJs} Label={'Node.js'} ColorRGB={'135, 207, 48'} IconRatio={0.7} />,
													]}
												/>
											</Col>
										</Row>

										<Row>
											<Col>
												<SkillsGroup
													GroupName='Design'
													Skills={[
														<SkillsGroup.Icon Image={Icons.Fusion360} Label={'Autodesk Fusion 360'} ColorRGB={'var(--nmd-tk-light-rgb)'} IconRatio={0.6} Master={true} />,
														<SkillsGroup.Icon Image={Icons.Meshmixer} Label={'Autodesk Meshmixer'} ColorRGB={'70, 85, 101'} IconRatio={0.65} />,
														<SkillsGroup.Icon Image={Icons.Unity} Label={'Unity'} ColorRGB={'var(--nmd-tk-light-rgb)'} IconRatio={0.75} />,
														<SkillsGroup.Icon Image={Icons.XD} Label={'Adobe XD'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />,
														<SkillsGroup.Icon Image={Icons.DevOps} Label={'Azure DevOps'} IconRatio={0.75} />,
													]}
												/>
											</Col>
										</Row>

										<Row>
											<Col>
												<SkillsGroup
													GroupName='Media'
													Skills={[
														<SkillsGroup.Icon Image={Icons.Audition} Label={'Adobe Audition'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />,
														<SkillsGroup.Icon Image={Icons.Illustrator} Label={'Adobe Illustrator'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />,
														<SkillsGroup.Icon Image={Icons.Photoshop} Label={'Adobe Photoshop'} IconRatio={0.6} ColorRGB={'17, 17, 17'} Master={true} />,
														<SkillsGroup.Icon Image={Icons.Premiere} Label={'Adobe Premiere'} IconRatio={0.6} ColorRGB={'17, 17, 17'} Master={true} />,
														<SkillsGroup.Icon Image={Icons.AfterEffects} Label={'Adobe After Effects'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />,
													]}
												/>
											</Col>
										</Row>
									</Card.Body>
								</GlassCard>
							</Col>

							<Col className='flex-grow-1'>
								<GlassCard
									ref={bioQuoteRef}
									className={`w-100 h-100`}
									style={{ borderRadius: breakpoint > Breakpoints.sm ? 'calc(var(--bio-size) / 2)' : 'var(--nmd-tk-border-radius-xxl)' }}
								>
									<Card.Body className='d-flex flex-column justify-content-center'>
										<Row className='justify-content-center'>
											<Col xs={'auto'}>
												<figure className='m-0'>
													<blockquote
														className={`BioQuote ${cleanBioQuote ? 'cleanBioQuote' : ''} blockquote m-0 py-4 px-4 font-brand lh-sm fst-italic text-nowrap user-select-none`}
														style={{
															fontSize: breakpoint <= Breakpoints.sm ? '1.75rem' : '3rem',
														}}
													>
														<div className='BioQuote_Open' onMouseEnter={(e) => cleanBioQuote_set(true)} onMouseLeave={(e) => cleanBioQuote_set(false)}>
															"
														</div>
														<div className='w-100 pe-5 text-start'>A jack of all trades</div>
														<div className='w-100 ps-5 text-end'>
															is <span className='BioQuote_Master'>a master</span> of <span className='BioQuote_None'>none,</span>
														</div>
														<div className='w-100 pe-5 text-start'>
															but <span className='BioQuote_BO'>oftentimes</span> better
														</div>
														<div className='w-100 ps-5 text-end'>than a master of one.</div>
														<div className='BioQuote_Close' onMouseEnter={(e) => cleanBioQuote_set(true)} onMouseLeave={(e) => cleanBioQuote_set(false)}>
															"
														</div>
													</blockquote>
													<figcaption
														className='blockquote-footer m-0 px-2 px-sm-5 pb-4 text-center user-select-none'
														style={{
															fontSize: breakpoint < Breakpoints.sm ? '1.25rem' : '1.75rem',
														}}
													>
														<TextRoller
															RepeatDelay={0}
															StepDuration={0.5}
															StepHold={5}
															Rollers={[
																<TextRoller.Roller Text={'Someone Famous'} />,
																<TextRoller.Roller Text={'William Shakespeare'} className={'BioQuote_WS'} />,
																<TextRoller.Roller Text={'Benjamin Franklin'} className='BioQuote_BF' />,
																<TextRoller.Roller Text={'Noah Miller'} className={'BioQuote_Me'} />,
																<TextRoller.Roller Text={'Who Knows'} className='BioQuote_WK' />,
															]}
														/>
													</figcaption>
												</figure>
											</Col>
										</Row>
									</Card.Body>
								</GlassCard>
							</Col>
						</Row>
					</Col>
				</Row>

				{/* <Row className='scroll-snap-element h-100 justify-content-center align-content-center'>
					<Col xs={'auto'}></Col>
				</Row>

				<Row
					className='scroll-snap-element h-200 justify-content-center bg-glass'
					style={{
						'--blur': '50px',
						WebkitMaskImage: 'linear-gradient(to bottom, transparent, rgba(var(--nmd-tk-black-rgb), 1) 60%)',
						maskImage: 'linear-gradient(to bottom, transparent, rgba(var(--nmd-tk-black-rgb), 1))',
					}}
				>
					<Col md={10} lg={8} xl={6}></Col>
				</Row>

				<Row
					className='scroll-snap-element h-100 justify-content-center bg-glass'
					style={{
						'--blur': '50px',
						background: 'rgba(var(--nmd-tk-body-rgb), 0.75)',
					}}
				>
					<Col md={10} lg={8} xl={6}></Col>
				</Row>

				<Row
					className='scroll-snap-element h-100 justify-content-center bg-glass'
					style={{
						'--blur': '50px',
						background: 'rgba(var(--nmd-tk-body-rgb), 0.75)',
					}}
				>
					<Col md={10} lg={8} xl={6}></Col>
				</Row>

				<Row
					className='scroll-snap-element h-100 justify-content-center'
					style={{
						background: 'linear-gradient(to bottom, rgba(var(--nmd-tk-body-rgb), 0.75), rgba(var(--nmd-tk-black-rgb), 1))',
					}}
				>
					<Col md={10} lg={8} xl={6}></Col>
				</Row>

				<Row
					className='scroll-snap-element h-100 justify-content-center'
					style={{
						background: 'rgba(var(--nmd-tk-black-rgb), 1)',
						WebkitMaskImage: 'radial-gradient(circle, transparent 15%, rgba(var(--nmd-tk-black-rgb), 1) 50%)',
						maskImage: 'radial-gradient(circle, transparent 50%, rgba(var(--nmd-tk-black-rgb), 1) 10%)',
					}}
				>
					<Col md={10} lg={8} xl={6}></Col>
				</Row> */}
			</Container>
		</>
	);
}

export default NoahMillerDesign;
