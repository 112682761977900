import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useContentBounds } from '../_Core/Layouts/Main';
import { MouseButtonMap, MouseDownState } from '../../API/Extensions';
import { toggleBodyClass } from '../../API/Utilities';

ViewerWindow.propTypes = {
	DefaultZoom: PropTypes.number,
	MinZoom: PropTypes.number,
	MaxZoom: PropTypes.number,
	ContentTemplate: PropTypes.element,
};

function ViewerWindow({ DefaultZoom = 1, MinZoom = 0.25, MaxZoom = 4, DefaultPosition, ContentTemplate }) {
	const { contentBounds } = useContentBounds();

	const [viewerPosition, viewerPosition_set] = useState();
	const [viewerScale, viewerScale_set] = useState(DefaultZoom);
	const [viewerGrabbed, viewerGrabbed_set] = useState(DefaultZoom);

	const [lastContactCenter, lastContactCenter_set] = useState();
	const [lastContactGap, lastContactGap_set] = useState();

	const handleCenterViewer = useCallback(() => {
		if (contentBounds) {
			viewerPosition_set({ top: contentBounds.height / 2, left: contentBounds.width / 2 });
			viewerScale_set(DefaultZoom);
		}
	}, [contentBounds, DefaultZoom]);

	const recenterTimeout = useRef();
	useEffect(() => {
		if (contentBounds) {
			if (recenterTimeout.current) clearTimeout(recenterTimeout.current);
			recenterTimeout.current = setTimeout(() => handleCenterViewer(), 500);
		}
	}, [handleCenterViewer, contentBounds]);

	const handlePinchZoom = useCallback(
		(currentGap) => {
			let delta = 0;
			if (lastContactGap) {
				delta = Math.abs(lastContactGap - currentGap);
			}

			if (delta > 5) {
				let direction = lastContactGap > currentGap ? -1 : 1;
				let scale = viewerScale + direction * 0.075;
				viewerScale_set(Math.min(Math.max(MinZoom, scale), MaxZoom));
			}

			lastContactGap_set(currentGap);
		},
		[viewerScale, MinZoom, MaxZoom, lastContactGap]
	);

	const handleWheelZoom = useCallback(
		(delta) => {
			let scale = viewerScale + (delta / 100) * 0.25 * -1;
			viewerScale_set(Math.min(Math.max(MinZoom, scale), MaxZoom));
		},
		[viewerScale, MinZoom, MaxZoom]
	);

	const handleContactMove = useCallback(
		({ contactPoints, contactCenter }) => {
			let touchContactCenter;
			if (contactPoints && contactPoints.length === 2) {
				let touch_0 = contactPoints.item(0);
				let touch_1 = contactPoints.item(1);

				let x = touch_0.pageX - touch_1.pageX;
				let y = touch_0.pageY - touch_1.pageY;

				let currentGap = Math.hypot(x, y);
				handlePinchZoom(currentGap);

				let currentContactX = (touch_0.pageX + touch_1.pageX) / 2;
				let currentContactY = (touch_0.pageY + touch_1.pageY) / 2;
				touchContactCenter = { x: currentContactX, y: currentContactY };
			}

			let currentContactCenter = contactCenter ?? touchContactCenter ?? lastContactCenter;

			let shiftX = 0;
			let shiftY = 0;

			if (lastContactCenter) {
				shiftX = currentContactCenter.x - lastContactCenter.x;
				shiftY = currentContactCenter.y - lastContactCenter.y;
			}

			lastContactCenter_set(currentContactCenter);
			if (Math.abs(shiftX) < 20 || Math.abs(shiftY) < 20) {
				const positionTop = viewerPosition ? viewerPosition.top : contentBounds ? contentBounds.height / 2 : 0;
				const positionLeft = viewerPosition ? viewerPosition.left : contentBounds ? contentBounds.width / 2 : 0;
				viewerPosition_set({ top: positionTop + shiftY, left: positionLeft + shiftX });
			}
		},
		[contentBounds, lastContactCenter, viewerPosition, handlePinchZoom]
	);

	const [waitingForDoubleClick, waitingForDoubleClick_set] = useState(false);
	const doubleClickTimeout = useRef();
	const handleTap = () => {
		toggleBodyClass('overscroll-behavior-y-contain', true);

		if (waitingForDoubleClick) {
			handleCenterViewer();
		} else {
			waitingForDoubleClick_set(true);

			if (doubleClickTimeout.current) clearTimeout(doubleClickTimeout.current);

			doubleClickTimeout.current = setTimeout(() => {
				waitingForDoubleClick_set(false);
			}, 300);
		}
	};

	const handleContactEnd = () => {
		toggleBodyClass('overscroll-behavior-y-contain', false);
		lastContactCenter_set(undefined);
		lastContactGap_set(undefined);
		viewerGrabbed_set(false);
	};

	return (
		<div
			className='ViewerWindow overscroll-behavior-y-contain w-100 h-100 overflow-hidden position-relative'
			style={{ cursor: viewerGrabbed ? 'move' : 'default' }}
			onContextMenu={(e) => {
				e.preventDefault();
				e.stopPropagation();
				return false;
			}}
			onWheel={(e) => {
				handleWheelZoom(e.deltaY);
			}}
			onMouseDown={(e) => {
				if (e.button === MouseButtonMap.RightButton) {
					viewerGrabbed_set(true);
				}
			}}
			onMouseUp={(e) => {
				if (e.button === MouseButtonMap.ScrollWheel) {
					handleCenterViewer();
				}

				handleContactEnd();
			}}
			onMouseMove={(e) => {
				if (MouseDownState[MouseButtonMap.RightButton]) {
					handleContactMove({ contactCenter: { x: e.pageX, y: e.pageY } });
				} else {
					viewerGrabbed_set(false);
				}
			}}
			onTouchStart={(e) => {
				let contactPoints = e.touches;
				if (contactPoints.length === 2) {
					handleTap();
				}
			}}
			onTouchMove={(e) => {
				let contactPoints = e.touches;
				if (contactPoints.length === 2) {
					handleContactMove({ contactPoints: contactPoints });
					viewerGrabbed_set(true);
				} else {
					handleContactEnd();
				}
			}}
			onTouchEnd={(e) => handleContactEnd()}
		>
			<div
				className='ViewerWindowContent overscroll-behavior-y-contain transform transition center-both'
				style={{
					'--transition-duration': viewerGrabbed ? '0s' : '1.5s',
					'--transition-timing-function': 'var(--timing-func-ease-out-soft)',
					'--top': viewerPosition ? `calc(${viewerPosition.top} * 1px)` : '50%',
					'--left': viewerPosition ? `calc(${viewerPosition.left} * 1px)` : '50%',
					'--scaleX': viewerScale,
					'--scaleY': viewerScale,
				}}
			>
				{ContentTemplate}
			</div>
		</div>
	);
}

export default ViewerWindow;
