import React from 'react';
import ProjectRoadmap from './ProjectRoadmap/ProjectRoadmap';

function Roadmap_DnDStatCalc() {
	return (
		<ProjectRoadmap
			Milestones={[
				{ Title: 'Alpha Version', Complete: true, InProgress: false },
				{ Title: 'Initial Migration', Complete: false, InProgress: true },

				{ Title: 'Convert: jQuery ⇨ React', Complete: false, InProgress: false },
				{ Title: 'Functionality Review', Complete: false, InProgress: false },
				{ Title: 'UI / UX Redesign', Complete: false, InProgress: false },
				{ Title: 'Tutorial First Draft', Complete: false },
				{ Title: 'Usability Review', Complete: false, InProgress: false },
				{ Title: 'Final Adaptations', Complete: false },
				{ Title: 'Tutorial Final Draft', Complete: false },
			]}
		/>
	);
}

export default Roadmap_DnDStatCalc;
