import React from 'react';
import ProjectRoadmap from './ProjectRoadmap/ProjectRoadmap';

function Roadmap_ErrorPage() {
	return (
		<ProjectRoadmap
			Title={'Broken Stuff Roadmap'}
			Milestones={[
				{ Title: 'Figure Out What Broke', Complete: false, InProgress: true },
				{ Title: 'Fix What Broke', Complete: false, InProgress: false },
			]}
			CompleteTitle={'No More Broken Stuff'}
		/>
	);
}

export default Roadmap_ErrorPage;
