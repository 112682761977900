import { useState } from 'react';
import MaterialIcon from './MaterialIcon';

function UserIcon({ photoUrl, className, style }) {
	const [photoError, setPhotoError] = useState(false);
	const [photoLoaded, setPhotoLoaded] = useState(false);

	return photoUrl && !photoError ? (
		<img
			src={photoUrl ?? ''}
			referrerPolicy='no-referrer'
			alt='Profile'
			className={'rounded-circle border border-1 ' + (className ?? '')}
			style={{ ...{ height: '100%', transition: '1.25s ease-out', opacity: photoLoaded ? 1 : 0 }, ...style }}
			onLoad={() => setPhotoLoaded(true)}
			onError={() => setPhotoError(true)}
		/>
	) : (
		<MaterialIcon
			Icon={'account_circle'}
			className={'text-secondary rounded-circle ' + (className ?? '')}
			style={{ ...{ height: '100%', fontSize: style && style.fontSize ? style.fontSize : style && style.height ? style.height : '100%' }, ...style }}
		/>
	);
}

export default UserIcon;
