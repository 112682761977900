import React from 'react';
import '../../styles/ComponentStyles/Grid.css';
import PropTypes from 'prop-types';
import { convertNumberToRange } from '../../API/Utilities';

Grid.propTypes = {
	Rows: PropTypes.number,
	Columns: PropTypes.number,
	StaggerRows: PropTypes.bool,
	VerticalRows: PropTypes.bool,
	CellTemplate: PropTypes.func,
};

function Grid({ Rows = 0, Columns = 0, StaggerRows = false, VerticalRows = false, CellOptions = { height: 2, width: 2, radius: 2, show_coords: true, coord_style: '0' }, CellTemplate }) {
	let xCount = VerticalRows ? Columns : Rows;
	let yCount = VerticalRows ? Rows : Columns;

	return (
		<div
			className='Grid position-relative'
			style={{
				'--cell-width': `calc(var(--content-width)/${yCount} * 1px)`,
				'--cell-height': `calc(var(--content-height)/${xCount} * 1px)`,
				'--cell-size': `min(var(--cell-width), var(--cell-height))`,
				width: `calc(var(--cell-size) * ${yCount})`,
				height: `calc(var(--cell-size) * ${xCount})`,
			}}
		>
			{Array.from({ length: Rows }).map((item, xIndex, arr) => {
				return Array.from({ length: Columns }).map((item, yIndex, arr) => {
					let rowIndex = !VerticalRows ? xIndex : yIndex;
					let columnIndex = VerticalRows ? xIndex : yIndex;

					let staggeredRow = StaggerRows && xIndex % 2 !== 0;
					let lastCell = yIndex === arr.length - 1;

					let cellNumber_row = VerticalRows ? yIndex + 1 : xIndex * yCount;
					let cellNumber_col = VerticalRows ? xIndex * xCount : yIndex + 1;
					let cellNumber_stagger = StaggerRows ? (xIndex - (xIndex % 2)) / 2 : 0;
					let cellNumber = cellNumber_row + cellNumber_col - cellNumber_stagger;

					return (
						<div
							key={`cell_frame_x${xIndex}-y${yIndex}`}
							id={`cell_frame_x${xIndex}-y${yIndex}`}
							className='GridCell transform'
							style={{
								zIndex: rowIndex + columnIndex,

								opacity: staggeredRow && lastCell ? '0' : '1',
								padding: `calc(1px * ${convertNumberToRange(CellOptions.radius, 0, 3, 0, 3)})`,

								width: staggeredRow && lastCell && !VerticalRows ? `calc(var(--cell-size)/2)` : `var(--cell-size)`,
								height: staggeredRow && lastCell && VerticalRows ? `calc(var(--cell-size)/2)` : `var(--cell-size)`,

								'--x-position': `calc(var(--cell-size) * ${columnIndex})`,
								'--x-offset': staggeredRow && !VerticalRows ? `calc(var(--cell-size) / 2)` : `0px`,

								'--y-position': `calc(var(--cell-size) * ${rowIndex})`,
								'--y-offset': staggeredRow && VerticalRows ? `calc(var(--cell-size) / 2)` : `0px`,

								'--left': `calc(var(--x-position) + var(--x-offset))`,
								'--top': `calc(var(--y-position) + var(--y-offset))`,

								'--transition-delay': `${(rowIndex + columnIndex) * 0.015}s`,
							}}
						>
							<div
								className='w-100 h-100 position-relative bg-transparent'
								style={{
									'--font-size': `calc(var(--cell-size)/5)`,
								}}
							>
								<div
									className='GridCellContent overflow-hidden transition transform center-both d-flex flex-row justify-content-center w-100 h-100 user-select-none text-center'
									style={{
										zIndex: '1',
										outline: 'var(--outline-width) solid var(--outline-color)',
										outlineOffset: 'var(--outline-offset)',
										borderRadius: `calc(${convertNumberToRange(CellOptions.radius, 0, 3, 0, 50)} * 1%)`,
										'--transition-delay': `${(rowIndex + columnIndex) * 0.015}s`,
									}}
								>
									{CellTemplate && CellTemplate({ xIndex, yIndex, rowIndex, columnIndex, cellNumber, xCount, yCount })}
								</div>

								<div
									className='transition transform center-both d-flex flex-row justify-content-center w-100 user-select-none pe-none font-monospace text-center lh-1'
									style={{
										opacity: CellOptions.show_coords ? '25%' : '0%',
										fontSize: 'var(--font-size)',
										zIndex: '5',
										'--transition-delay': `${(rowIndex + columnIndex) * 0.015}s`,
									}}
								>
									{CellOptions.coord_style === '0' && (
										<div
											className='w-auto text-uppercase'
											style={{
												zIndex: '-1',
											}}
										>
											<div className='d-flex flex-row justify-content-between w-100'>
												<div>{`R:`}&nbsp;</div>
												<div>{`${staggeredRow && VerticalRows ? rowIndex + 0.5 + 1 : rowIndex + 1}`}</div>
											</div>
											<div className='d-flex flex-row justify-content-between w-100'>
												<div>{`C:`}&nbsp;</div>
												<div>{`${staggeredRow && !VerticalRows ? columnIndex + 0.5 + 1 : columnIndex + 1}`}</div>
											</div>
										</div>
									)}

									{CellOptions.coord_style === '1' && (
										<div
											className='w-auto text-uppercase'
											style={{
												zIndex: '-1',
											}}
										>
											<div className='d-flex flex-row justify-content-between w-100'>
												<div>{`${cellNumber}`}</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					);
				});
			})}
		</div>
	);
}

export default Grid;
