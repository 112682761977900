import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/ComponentStyles/Emblem.css';
import NM from '../../images/NM_WoBoT.png';
import Pawn from '../../images/P_WoBoT.png';
import { convertNumberToRange } from '../../API/Utilities';
import { useContentBounds } from '../_Core/Layouts/Main';
import { MousePosition } from '../../API/Extensions';

Emblem.propTypes = {
	Emblem: PropTypes.oneOf(['NM', 'Pawn']),
};

const updateTargetInterval = { current: undefined };

function Emblem({ className = '', style, Emblem = 'NM' }) {
	const { contentBounds } = useContentBounds();

	const emblemRef = useRef();
	const [hovering, hovering_set] = useState(false);
	const [targetDelta, targetDelta_set] = useState({ x: 0, y: 0 });

	const handleMouseEnter = useCallback(
		(e) => {
			if (emblemRef.current && !hovering) {
				handleUpdateDelta();
				!hovering && hovering_set(true);

				// let emblemBounds = emblemRef.current.getBoundingClientRect();
				// let centerDelta = { x: MousePosition.x - (emblemBounds.left + emblemBounds.width / 2), y: MousePosition.y - (emblemBounds.top + emblemBounds.height / 2) };
				// targetDelta_set(centerDelta);

				// setTimeout(() => {
				// 	let emblemBounds = emblemRef.current.getBoundingClientRect();
				// 	let centerDelta = { x: MousePosition.x - (emblemBounds.left + emblemBounds.width / 2), y: MousePosition.y - (emblemBounds.top + emblemBounds.height / 2) };
				// 	targetDelta_set(centerDelta);

				// 	updateTargetInterval.current && clearInterval(updateTargetInterval.current);
				// 	updateTargetInterval.current = setInterval(() => {
				// 		handleUpdateDelta();
				// 		!hovering && hovering_set(true);
				// 	}, 1);
				// }, 750);
			}
		},
		[emblemRef, hovering]
	);

	// const handleTouchStart = useCallback(
	// 	(e) => {
	// 		if (emblemRef.current && !hovering) {
	// 			updateTargetInterval.current && clearInterval(updateTargetInterval.current);
	// 			updateTargetInterval.current = setInterval(() => {
	// 				handleUpdateDelta();
	// 				!hovering && hovering_set(true);
	// 			}, 1);
	// 		}
	// 	},
	// 	[emblemRef, hovering]
	// );

	const handleMouseLeave = useCallback((e) => {
		updateTargetInterval.current && clearInterval(updateTargetInterval.current);
		hovering_set(false);
		targetDelta_set({ x: 0, y: 0 });
	}, []);

	const handleUpdateDelta = (e) => {
		if (emblemRef.current && emblemRef.current.matches(':hover')) {
			let emblemBounds = emblemRef.current.getBoundingClientRect();
			let centerDelta = { x: MousePosition.x - (emblemBounds.left + emblemBounds.width / 2), y: MousePosition.y - (emblemBounds.top + emblemBounds.height / 2) };

			targetDelta_set(centerDelta);
		}
	};

	const [dropShadowString, dropShadowString_set] = useState(`drop-shadow(-10px 22.5px rgba(var(--nmd-tk-white-rgb), 1))`);
	const [translateZString, translateZString_set] = useState(`0px`);
	const [topString, topString_set] = useState(`calc(-50%)`);
	const [leftString, leftString_set] = useState(`calc(-50%)`);
	const [rotateXString, rotateXString_set] = useState(`0deg`);
	const [rotateYString, rotateYString_set] = useState(`0deg`);

	useEffect(() => {
		const maxRotation = 15;
		rotateXString_set(`${convertNumberToRange(targetDelta.y * -1, (contentBounds.height / 2) * -1, contentBounds.height / 2, maxRotation * -1, maxRotation)}deg`);
		rotateYString_set(`${convertNumberToRange(targetDelta.x, (contentBounds.width / 2) * -1, contentBounds.width / 2, maxRotation * -1, maxRotation)}deg`);

		const maxShift = 250;
		topString_set(`calc(-50% - ${convertNumberToRange(targetDelta.y * -1, (contentBounds.height / 2) * -1, contentBounds.height / 2, maxShift * -1, maxShift)}px)`);
		leftString_set(`calc(-50% - ${convertNumberToRange(targetDelta.x * -1, (contentBounds.width / 2) * -1, contentBounds.width / 2, maxShift * -1, maxShift)}px)`);

		dropShadowString_set(
			`drop-shadow(${convertNumberToRange(targetDelta.x * -1, (contentBounds.width / 2) * -1, contentBounds.width / 2, (maxShift / 2) * -1, maxShift / 2)}px ${convertNumberToRange(
				targetDelta.y * -1,
				(contentBounds.height / 2) * -1,
				contentBounds.height / 2,
				(maxShift / 2) * -1,
				maxShift / 2
			)}px ${convertNumberToRange(
				(Math.abs(targetDelta.x) + Math.abs(targetDelta.y)) / 2,
				((contentBounds.width / 2 + contentBounds.height / 2) / 2) * -1,
				(contentBounds.width / 2 + contentBounds.height / 2) / 2,
				5,
				15
			)}px rgba(var(--nmd-tk-white-rgb), 1))`
		);

		const maxZ = 250;
		translateZString_set(
			`${convertNumberToRange(
				((Math.abs(targetDelta.x) + Math.abs(targetDelta.y)) / 2) * -1,
				((contentBounds.width / 2 + contentBounds.height / 2) / 2) * -1,
				(contentBounds.width / 2 + contentBounds.height / 2) / 2,
				maxZ * -1,
				maxZ
			)}px`
		);
	}, [targetDelta, contentBounds]);

	const [transitionDurString, transitionDurString_set] = useState(`0.75s`);
	useEffect(() => {
		const maxTransition = 0.75;
		transitionDurString_set(`${hovering ? 0 : emblemRef.current && emblemRef.current.matches(':hover') ? maxTransition / 2 : maxTransition}s`);
	}, [hovering]);

	return (
		<div
			className={`Emblem h-100 w-100 transform-3d ${className ?? ''}`}
			style={{ ...style }}
			ref={emblemRef}
			onMouseMove={handleUpdateDelta}
			onMouseOverCapture={handleMouseEnter}
			onMouseOutCapture={handleMouseLeave}
			onTouchMove={(e) => {
				handleMouseEnter();
				handleUpdateDelta();
			}}
			onTouchEnd={handleMouseLeave}
		>
			{Emblem === 'Pawn' ? (
				<img
					src={Pawn}
					alt='Pawn Logo'
					className='transform transform-3d center-both transition pe-none max-w-150 max-h-125'
					style={{
						opacity: '1',
						'--transition-duration': transitionDurString,
						'--translateX': leftString,
						'--translateY': topString,
						'--translateZ': translateZString,
						'--rotateX': rotateXString,
						'--rotateY': rotateYString,
						filter: `invert(100%) ${dropShadowString}`,
						willChange: 'filter, transform, transition',
					}}
				/>
			) : (
				<img
					src={NM}
					alt='NM Logo'
					className='transform transform-3d center-both transition pe-none max-w-100 max-h-100 p-5'
					style={{
						opacity: '1',
						'--transition-duration': transitionDurString,
						'--translateX': leftString,
						'--translateY': topString,
						'--translateZ': translateZString,
						'--rotateX': rotateXString,
						'--rotateY': rotateYString,
						filter: `invert(100%) ${dropShadowString}`,
						willChange: 'filter, transform, transition',
					}}
				/>
			)}
		</div>
	);
}

export default Emblem;
