import '../../../../styles/ComponentStyles/ProjectRoadmap.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Milestone from './Milestone';

ProjectRoadmap.propTypes = {
	Title: PropTypes.string,
	CompleteTitle: PropTypes.string,
	Milestones: PropTypes.arrayOf(PropTypes.shape({ Title: PropTypes.string.isRequired, Index: PropTypes.number, Complete: PropTypes.bool, InProgress: PropTypes.bool })).isRequired,
	ProjectComplete: PropTypes.bool,
	Swerve: PropTypes.bool,
};

function ProjectRoadmap({ Title, CompleteTitle, Milestones = [], ProjectComplete, Swerve }) {
	const allComplete = Milestones.filter((m) => m.Complete).length === Milestones.length;

	return (
		<div className={`ProjectRoadmap w-100 h-100 ${Swerve ?? Milestones.length < 10 ? 'swerve' : ''}`}>
			<Row className='ProjectRoadmap_Title justify-content-center'>
				<Col xs={'auto'}>
					<div className='fs-1 text-center'>{Title ?? 'Project Roadmap'}</div>
					<hr className='mx-n3 my-0 border-4 rounded-pill' />
				</Col>
			</Row>

			{Milestones &&
				Milestones.length > 0 &&
				Milestones.map((milestone, idx) => {
					return <Milestone key={idx} Title={milestone.Title} Complete={ProjectComplete ?? milestone.Complete} InProgress={milestone.InProgress} Index={idx} />;
				})}

			<Row className='ProjectRoadmap_Complete justify-content-center pb-4'>
				<Col xs={'auto'}>
					<hr className='mx-n3 my-0 border-4 rounded-pill' />
					<div className='fs-1 text-center' style={{ color: ProjectComplete || allComplete ? 'var(--nmd-tk-teal)' : 'var(--nmd-tk-mid)' }}>
						{CompleteTitle ?? 'Project Complete'}
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default ProjectRoadmap;
